import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TimeSpentRequest } from '../models/TimeSpentRequest';

@Injectable()
export class TimeSpentService {
  constructor(private http: HttpClient) {
  }

  save(timeSpent: TimeSpentRequest): any {
    return this.http.post(`irri/api/v1/irr`, timeSpent);
  }

  update(timeSpent: TimeSpentRequest): any {
    return this.http.put(`irri/api/v1/irr`, timeSpent);
  }

  sendToValidation(timeSpent: TimeSpentRequest): any {
    return this.http.put(`irri/api/v1/irr/request-validate`, timeSpent);
  }

  reject(id: any): any {
    return this.http.put(`irri/api/v1/irr/reject/${id}`, {});
  }

  validate(timeSpent: TimeSpentRequest): any {
    return this.http.put(`irri/api/v1/irr/validate`, timeSpent);
  }
}
