import {TranslateCompiler} from '@ngx-translate/core';

export class CustomCompiler implements TranslateCompiler {

  compile(a: string, b: string) {
    return '{}';
  }

  compileTranslations(translation: any, b: string) {
    const translations = {};
    if (translation.length > 1 && Array.isArray(translation[0])) {
      translation[0].forEach(t => {
        translations[t.key] = t.value;
      });
      console.log(translations);
      return Object.assign(translations, translation[1]);
    } else {
      return translation[1];
    }
  }

}
