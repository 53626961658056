import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {HttpTranslateService} from '../services/http-translate.service';

export class CustomTranslationLoader extends TranslateLoader {

  constructor(public httpTranslateService: HttpTranslateService) {
    super();
  }

  getTranslation(lang: string): Observable<any> {
    return this.httpTranslateService.getTranslationByLang(lang);
  }
}
