import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IrriService } from './services/irri.service';
import { IRRResolverService } from './services/irr-resolver.service';
import { AuditService } from './services/audit.service';
import { TranslateModule, TranslateLoader, TranslateService, TranslateCompiler } from '@ngx-translate/core';
import { CustomCompiler } from './models/CustomCompiler';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { of } from 'rxjs';
import { RpgService } from './services/rpg.service';
import { ResponseInterceptor } from './shared/util/response.interceptor';
import { TimeSpentService } from './services/time-spent.service';
import { ProfessionalService } from './services/professional.service';
import { Util } from './shared/util/util';
import { HttpTranslateService } from './services/http-translate.service';
import { CustomTranslationLoader } from './models/CustomTranslationLoader';
import { SharedModule } from './shared/shared.module';

// required for AOT compilation
//export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  //return new TranslateHttpLoader(http);
//}

registerLocaleData(localeFr);

export function init(translate: TranslateService) {
  return () => {
      translate.addLangs(['en', 'fr']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
      return of({});
  };
}

export function createCustomTranslateLoader(httpTranslateService: HttpTranslateService) {
  return new CustomTranslationLoader(httpTranslateService);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ComponentsModule,
    PagesModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: (createCustomTranslateLoader), deps: [HttpTranslateService]},
      compiler: {provide: TranslateCompiler, useClass: CustomCompiler}
    }),
  ],
  providers: [
    IrriService,
    TimeSpentService,
    ProfessionalService,
    IRRResolverService,
    AuditService,
    RpgService,
    Util,
    HttpTranslateService,
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: APP_INITIALIZER,
      useFactory: (init),
      deps: [TranslateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
