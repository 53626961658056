import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RpgService {

  constructor(public http: HttpClient) { }

  getUsersByLastNameAndFirstName(lastName, firstName): any {
    return this.http.get<any>(`irri/api/v1/rpg/find?lastName=${lastName ? lastName : ''}&firstName=${firstName ? firstName : ''}`);
  }

  getUserByIup(iup): any {
    return this.http.get<any>(`irri/api/v1/rpg/iup?criteria=${iup}`);
  }
}
