import { Component, OnInit, ViewChild, SimpleChanges, AfterViewInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {catchError, map, startWith} from 'rxjs/operators';
import {merge, of as observableOf, SchedulerLike} from 'rxjs';
import { PaginationSort } from '../../../models/pagination-sort';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/User';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss']
})
export class DashboardTableComponent implements  OnInit, AfterViewInit, OnChanges {

  user: User;

  displayedColumns: Array<any>;
  selection: SelectionModel<any>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() dataSource: MatTableDataSource<any>;
  @Input() total: string;
  @Input() size: number;
  @Output() pagination = new EventEmitter<PaginationSort>();
  @Output() open = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();
  @Output() reject = new EventEmitter<any>();
  @Output() validate = new EventEmitter<any>();
  @Output() audit = new EventEmitter<any>();

  constructor(
    public userService: UserService
  ) {

    this.displayedColumns = [
      'id',
      'declarationType',
      'authorFirstName',
      'authorLastName',
      'authorEntity',
      'status',
      'actionDate',
      'actions'
    ];

    this.selection = new SelectionModel<any>(true, []);

    this.userService.user.subscribe(
      (data) => {
          this.user = data;
      },
      (err) => console.error(err)
    );

  }

  ngOnInit(): void{
    this.size = (this.size === undefined) ? 20 : this.size;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.total) {
        this.paginator.firstPage();
    }
  }


  ngAfterViewInit(): void{
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
          startWith(<SchedulerLike>{}),
          map(() => {
            this.selection.clear();
            return new PaginationSort(this.paginator.pageSize, this.paginator.pageIndex, this.sort.active, this.sort.direction);
          }),
          catchError(() => {
            return observableOf(new PaginationSort());
          })
      ).subscribe({
      next: data => {
        this.pagination.emit(data);
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
      }
    });
  }

  openIrr(id, type): void {
    this.open.emit({id, type});
  }

  editIrr(id, type): void {
    this.edit.emit({id, type});
  }

  cancelIrr(id, type): void {
    this.cancel.emit(id);
  }

  rejectIrr(id, type): void {
    this.reject.emit(id);
  }

  validateIrr(id, type): void {
    this.validate.emit({id, type});
  }

  viewAudits(id, type): void {
    this.audit.emit(id);
  }

}
