import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  action: string;
  param: string;

  @HostListener('document:keydown.enter', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.srcElement && (event.target as HTMLInputElement).localName !== 'textarea') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Constructor of confirm modal component
   * @param dialogRef
   * @param data
   */
  constructor(public dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.param = data.param;
  }

  /**
   * Init confirm modal
   */
  ngOnInit(): void {
  }

  /**
   * Cancel the confirm modal
   */
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  /**
   * Respond to the confirm modal
   */
  confirm(): void {
    this.dialogRef.close(
      {
        action: this.action,
        param: this.param
      }
    );
  }

}
