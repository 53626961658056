import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component'
import { DashboardCriteriaComponent } from './dashboard-criteria/dashboard-criteria.component'
import { DashboardTableComponent } from './dashboard-table/dashboard-table.component'
import { MaterialComponentsModule } from '../../components/material-components/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { createCustomTranslateLoader } from 'src/app/components/components.module';
import { HttpTranslateService } from 'src/app/services/http-translate.service';
import { CustomCompiler } from 'src/app/models/CustomCompiler';


@NgModule({
  declarations: [
    DashboardComponent,
    DashboardCriteriaComponent,
    DashboardTableComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule.forChild({
      loader: {provide: TranslateLoader, useFactory: (createCustomTranslateLoader), deps: [HttpTranslateService]},
      compiler: {provide: TranslateCompiler, useClass: CustomCompiler}
    })
  ],
  exports: [
    DashboardComponent,
  ]
})
export class DashboardModule { }
