import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'profile'
})
export class ProfilePipe implements PipeTransform {
  transform(profileCode: string): string {
    let profileDescription;
    switch ( profileCode ) {
      case environment.adminProfile:
        profileDescription = 'Administrator';
        break;
      case environment.validatorProfile:
        profileDescription = 'Validator';
        break;
      case environment.userProfile:
        profileDescription = 'User';
        break;
      default:
        profileDescription =  '';
   }
    return profileDescription;
  }

}
