import { ɵescapeHtml as escapeHtml } from '@angular/platform-browser';

export class Utils {

  static download(blob, name): void {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const fileName = escapeHtml(name);
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const file = new File([blob], name);
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    }
  }

  static initObject(source, fields): void {
    fields.forEach(sourcekey => {
      source[sourcekey] = null;
    });
  }

}

declare global {
  interface Navigator {
    msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
  }
}
