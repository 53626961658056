import { Injectable } from '@angular/core';
import { ProfessionalRequest } from '../models/ProfessionalRequest';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProfessionalService {
  constructor(private http: HttpClient) {
  }

  save(professional: ProfessionalRequest): any {
    return this.http.post(`irri/api/v1/irr`, professional);
  }

  update(professional: ProfessionalRequest): any {
    return this.http.put(`irri/api/v1/irr`, professional);
  }

  sendToValidation(professional: ProfessionalRequest): any {
    return this.http.put(`irri/api/v1/irr/request-validate`, professional);
  }

  reject(id: any): any {
    return this.http.put(`irri/api/v1/irr/reject/${id}`, {});
  }

  validate(professional: ProfessionalRequest): any {
    return this.http.put(`irri/api/v1/irr/validate`, professional);
  }

}
