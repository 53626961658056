import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LegalNoticeComponent } from './pages/legal-notice/legal-notice.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { IRRComponent } from './pages/form/irr/irr.component';
import { IRRResolverService } from './services/irr-resolver.service';
import { TimeSpentComponent } from './pages/form/time-spent/time-spent.component';
import { ProfessionalComponent } from './pages/form/professional/professional.component';


const routes: Routes = [
  {path : 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path : 'irr/view/:id', component: IRRComponent, resolve: {irr: IRRResolverService}, canActivate: [AuthGuard]},
  {path : 'irr/edit/:id', component: IRRComponent, resolve: {irr: IRRResolverService}, canActivate: [AuthGuard]},
  {path : 'irr', component: IRRComponent, canActivate: [AuthGuard]},
  {path : 'time-spent/view/:id', component: TimeSpentComponent, resolve: {irr: IRRResolverService}, canActivate: [AuthGuard]},
  {path : 'time-spent/edit/:id', component: TimeSpentComponent, resolve: {irr: IRRResolverService}, canActivate: [AuthGuard]},
  {path : 'time-spent', component: TimeSpentComponent, canActivate: [AuthGuard]},
  {path : 'professional/view/:id', component: ProfessionalComponent, resolve: {irr: IRRResolverService}, canActivate: [AuthGuard]},
  {path : 'professional/edit/:id', component: ProfessionalComponent, resolve: {irr: IRRResolverService}, canActivate: [AuthGuard]},
  {path : 'professional', component: ProfessionalComponent, canActivate: [AuthGuard]},
  {path : 'legal-notice', component: LegalNoticeComponent},
  {path : 'contact', component: ContactComponent},
  {path : 'error', component: ErrorComponent},
  {path : '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: '**',   component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
