import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { IRRRequest } from '../models/IRRRequest';
import { Criteria } from '../models/Criteria';
import { PaginationSort } from '../models/pagination-sort';
import { Observable } from 'rxjs';


@Injectable()
export class IrriService {
  constructor(private http: HttpClient) {
  }

  save(irr: IRRRequest): any {
    return this.http.post(`irri/api/v1/irr`, irr);
  }

  update(irr: IRRRequest): any {
    return this.http.put(`irri/api/v1/irr`, irr);
  }

  cancel(id: any): any {
    return this.http.put(`irri/api/v1/irr/cancel/${id}`, {});
  }

  sendToValidation(irr: IRRRequest): any {
    return this.http.put(`irri/api/v1/irr/request-validate`, irr);
  }

  reject(id: any): any {
    return this.http.put(`irri/api/v1/irr/reject/${id}`, {});
  }

  validate(irr: IRRRequest): any {
    return this.http.put(`irri/api/v1/irr/validate`, irr);
  }

  get(id: any): Observable<IRRRequest>{
    return this.http.get<IRRRequest>(`irri/api/v1/irr/${id}`);
  }

  getByCriteria(criteria: Criteria, paginationSort: PaginationSort): any{
    return this.http.post<any>(
      `irri/api/v1/irr/find?page=${paginationSort.pageIndex}&size=${paginationSort.pageSize}&sort=${paginationSort.sort},${paginationSort.order}`,
      criteria);
  }

  getSynthesis(criteria: Criteria): any{
    return this.http.post(`irri/api/v1/irr/reporting/synthesis`, criteria,
    {observe: 'response', responseType: 'blob'});
  }

}
