import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  user: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
    public http: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  retriveUser() {
    return this.http.get<User>('irri/api/v1/irr/user').subscribe(
      data => {
          this.user.next(new User(data));
      }
    );
  }

  isUserAuthorized(): Observable<boolean> {
    return this.http.get<User>('irri/api/v1/irr/user').pipe(
      map(value => {
          this.user.next(new User(value));

          if(this.user.value.authorities.indexOf(environment.userProfile) == -1
            && this.user.value.authorities.indexOf(environment.validatorProfile) == -1
            && this.user.value.authorities.indexOf(environment.adminProfile) == -1){
            this.openErrorPopup("You do not have profile associated to your user. Contact the administrator.");
            return false;
          }else if(!this.user.value.country || !this.user.value.etabCode){
            this.openErrorPopup("Your user has no RPG register or your register must be fixed. Contact the administrator.");
            return false;
          }

          return (this.user.value.authorities.indexOf(environment.userProfile) !== -1
            || this.user.value.authorities.indexOf(environment.validatorProfile) !== -1
            || this.user.value.authorities.indexOf(environment.adminProfile) !== -1)
            && this.user.value.country != ''
            && this.user.value.etabCode != '';
      })
    );
  }

  openErrorPopup(error: string): void {
    this.snackBar.open(error, null, {
      verticalPosition: 'top',
      duration: 5000
    });
  }

}
