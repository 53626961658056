import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { TimeSpentRequest } from '../../../models/TimeSpentRequest';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, startWith, debounceTime, switchMap, map, catchError } from 'rxjs/operators';
import { TimeSpentService } from '../../../services/time-spent.service';
import { ConfirmComponent } from '../../../components/confirm/confirm.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { Observable, of as observableOf } from 'rxjs';
import { RpgService } from '../../../services/rpg.service';
import { HttpTranslateService } from '../../../services/http-translate.service';

@Component({
  selector: 'app-time-spent',
  templateUrl: './time-spent.component.html',
  styleUrls: ['./time-spent.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class TimeSpentComponent implements OnInit {

  @Input() timeSpent: TimeSpentRequest;
    timeSpentForm: UntypedFormGroup;
    actionPole: any[];
    timeEstimation: any[];
    entities: any[];
    isLoadingResults = false;
    isEdit = false;
    isViewOnly = false;

    filteredAuthorFirstNameRpg: Observable<any>;
    filteredAuthorLastNameRpg: Observable<any>;

  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private timeSpentService: TimeSpentService,
    private translate: TranslateService,
    private httpTranslateService: HttpTranslateService,
    public route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public adapter: DateAdapter<any>,
    private rpgService: RpgService
  ) {

    this.translate.onLangChange.subscribe(data => this.adapter.setLocale(data.lang));

    router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(event => {
          this.isViewOnly = (event.url.indexOf('/view') >= 0);
          this.isEdit = (event.url.indexOf('/edit') >= 0);

          if (this.isEdit || this.isViewOnly){
            this.route.data.subscribe((data: any) => {
              this.timeSpent = data.irr;

              this.timeSpentForm.patchValue({
                id: this.timeSpent.id,
                authorActionPole: this.timeSpent.authorActionPole,
                authorEntity: this.timeSpent.authorEntity,
                employeeFirstName: this.timeSpent.employeeFirstName,
                employeeLastName: this.timeSpent.employeeLastName,
                employeeIup: this.timeSpent.employeeIup,
                employeeEmail: this.timeSpent.employeeEmail,
                timeEstimation: this.timeSpent.timeEstimation,
                accessToParliament: this.timeSpent.accessToParliament,
                actionRepresentationInterest: this.timeSpent.actionRepresentationInterest,
                decisionComment: this.timeSpent.decisionComment
              });
            });
          }

          this.fillDropdowns();
    });

    this.timeSpentForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      authorActionPole: new UntypedFormControl(''),
      authorEntity: new UntypedFormControl(''),
      employeeFirstName: new UntypedFormControl(''),
      employeeLastName: new UntypedFormControl(''),
      employeeIup: new UntypedFormControl(''),
      employeeEmail: new UntypedFormControl(''),
      timeEstimation: new UntypedFormControl(''),
      accessToParliament: new UntypedFormControl(''),
      actionRepresentationInterest: new UntypedFormControl(''),
      decisionComment: new UntypedFormControl('')
    });

    this.translate.onLangChange.subscribe(() => {
      this.fillDropdowns();
    });

    this.filteredAuthorFirstNameRpg = this.timeSpentForm.get('employeeFirstName').valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      switchMap(value => {
        if (value && value.length >= 3){
          this.timeSpentForm.patchValue({
            employeeIup: '',
            employeeEmail: '',
          });
          return (this.rpgService.getUsersByLastNameAndFirstName('', value));
        }
        return observableOf([]);
      }),
      map(data => {
          return data;
      }),
      catchError(() => {
          return observableOf([]);
      })
    );

    this.filteredAuthorLastNameRpg = this.timeSpentForm.get('employeeLastName').valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      switchMap(value => {
        if (value && value.length >= 3){
          this.timeSpentForm.patchValue({
            employeeIup: '',
            employeeEmail: '',
          });
          return (this.rpgService.getUsersByLastNameAndFirstName(value, ''));
        }
        return observableOf([]);
      }),
      map(data => {
          return data;
      }),
      catchError(() => {
          return observableOf([]);
      })
    );

  }

  ngOnInit(): void {
    this.adapter.setLocale(this.translate.currentLang);
    this.changeDetectorRef.detectChanges();
  }

  fillDropdowns(): void{
    if (!this.isViewOnly){
      this.httpTranslateService.getTranslationByParent('ACTION_POLES').subscribe((data: any) => {
        this.actionPole = data;
      });

      this.httpTranslateService.getTranslationByParent('ENTITIES').subscribe((data: any) => {
        this.entities = data;
      });
    }else{
      this.httpTranslateService.getAllTranslationByParent('ACTION_POLES').subscribe((data: any) => {
        this.actionPole = data;
      });

      this.httpTranslateService.getAllTranslationByParent('ENTITIES').subscribe((data: any) => {
        this.entities = data;
      });
    }

    this.translate.get('TIME_ESTIMATION').subscribe((data: any) => {
      this.timeEstimation = data;
    });
  }

  confirmActionGDPR(action: string): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '40%',
      disableClose: true,
      data: {
        action
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'save'){
          this.save();
        }else if (result.action === 'update'){
          this.update();
        }else if (result.action === 'requestValidate'){
          this.requestValidate();
        }else if (result.action === 'rejectTimeSpent'){
          this.rejectTimeSpent();
        }else if (result.action === 'validateTimeSpent'){
          this.validateTimeSpent();
        }
      }
    });
  }

  save(): void {
    if ( this.timeSpentForm.valid ) {
      this.isLoadingResults = true;
      const timeSpentRequest = this.timeSpentForm.getRawValue();

      console.log(timeSpentRequest);

      timeSpentRequest.declarationType = 'TS';

      this.timeSpentService.save(timeSpentRequest).subscribe(
        (data) => {
          this.isLoadingResults = false;
          this.router.navigate(['/dashboard']);
        }
      );

    } else {
      this.validateFormFields(this.timeSpentForm);
      this.timeSpentForm.updateValueAndValidity();
    }

  }

  update(): void {
    this.validateFormFields(this.timeSpentForm);
    this.timeSpentForm.updateValueAndValidity();

    if ( this.timeSpentForm.valid ) {
      this.isLoadingResults = true;
      const timeSpentRequest = this.timeSpentForm.getRawValue();

      timeSpentRequest.declarationType = 'TS';

      this.timeSpentService.update(timeSpentRequest).subscribe(
        (data) => {
          this.isLoadingResults = false;
          this.router.navigate(['/dashboard']);
        }
      );

    } else {
      this.timeSpentForm.updateValueAndValidity();
    }

  }

  requestValidate(): void {
    if ( this.timeSpentForm.valid ) {
      this.isLoadingResults = true;

      const timeSpentRequest = this.timeSpentForm.getRawValue();

      timeSpentRequest.declarationType = 'TS';

      this.timeSpentService.sendToValidation(timeSpentRequest).subscribe(
        (data) => {
          this.isLoadingResults = false;
          this.router.navigate(['/dashboard']);
        },
        (error) => {
          this.isLoadingResults = false;
          console.log(error);
        }
      );
    }
  }

  rejectTimeSpent(): void {
    this.isLoadingResults = true;
    this.timeSpentService.reject(this.timeSpent.id).subscribe(
      (data) => {
        this.isLoadingResults = false;
        this.router.navigate(['/dashboard']);
      },
      (error) => {
        this.isLoadingResults = false;
        console.log(error);
      }
    );
  }

  validateTimeSpent(): void {
    this.isLoadingResults = true;

    const timeSpentRequest = this.timeSpentForm.getRawValue();

    timeSpentRequest.declarationType = 'TS';

    this.timeSpentService.validate(timeSpentRequest).subscribe(
      (data) => {
        this.isLoadingResults = false;
        this.router.navigate(['/dashboard']);
      },
      (error) => {
        this.isLoadingResults = false;
        console.log(error);
      }
    );
  }

  validateFormFields(fields) {
    try {
      Object.entries(fields.controls).forEach((control: any) => {
        console.log(control);
        if (control[0] === 'publicResponsibles' || control[0] === 'peopleInvolved'
        ) {
          this.validateFormFields(control[1]);
        } else {
          if (control[1].controls) {
            Object.entries(control[1].controls).forEach((c: any) => {
              c[1].touched = true;
            });
          } else {
            control[1].touched = true;
          }

        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  employeePatchValues(data): void{
    this.timeSpentForm.patchValue({
      employeeIup: data.iup,
      employeeEmail: data.email,
      employeeFirstName: data.firstname,
      employeeLastName: data.lastname
    }, {onlySelf: true, emitEvent: false});
  }

}
