export class PaginationSort {

    pageSize: number;
    pageIndex: number;
    sort: string;
    order: string;

    constructor(pageSize?: number, pageIndex?: number, sort?: string, order?: string) {
        this.pageSize = pageSize || 8;
        this.pageIndex = pageIndex || 0;
        this.sort = sort || 'status';
        this.order = order || 'asc';
    }

}