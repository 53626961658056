import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AuditService } from '../../services/audit.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit, AfterViewInit {

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  isLoadingResults = true;
  id: string;

  displayedColumns = ['irr', 'activity', 'actionDate', 'lastNameOfUser', 'nameOfUser'];

  constructor(
    public translate: TranslateService,
    private auditService: AuditService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AuditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.id = this.data.id;
  }

  ngAfterViewInit(): void {
    this.auditService.getAuditsByIRR(this.id).subscribe(
      (res) => {
        this.isLoadingResults = false;
        this.dataSource.data = res;
      },
      (err) => {
        console.error(err);
        this.isLoadingResults = false;
      }
    );
  }

  close(): void {
    this.dialogRef.close(false);
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

}
