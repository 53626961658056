import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  message:String;
  code:String;
  timestamp:String;

  constructor(private route:ActivatedRoute) { 
    this.route.params.subscribe(
      (data) => {
        this.message = data.message ? data.message : '';
        this.code = data.message ? '[' + data.status + ']' : '';
        this.timestamp = data.message ? '[' + data.errorTime + ']' : '';
      }
    )
  }

  ngOnInit(): void {
  }

}
