import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/User';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-criteria',
  templateUrl: './dashboard-criteria.component.html',
  styleUrls: ['./dashboard-criteria.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DashboardCriteriaComponent implements OnInit {
  criteriaApplied: boolean;
  criteriaExpanded: boolean;
  criteriaForm: UntypedFormGroup;
  statuses: Array<any>;
  irriLocations: Array<any>;
  types: Array<any>;
  user: User;

  @Output() criteria = new EventEmitter<{}>();
  @Output() synthesis = new EventEmitter<{}>();

  constructor(
    private userService: UserService,
    public adapter: DateAdapter<any>,
    private translate: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.translate.onLangChange.subscribe((data) =>
      this.adapter.setLocale(data.lang)
    );

    this.criteriaApplied = false;

    this.criteriaForm = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.maxLength(64)),
      type: new UntypedFormControl(null),
      authorFirstName: new UntypedFormControl(null, Validators.maxLength(64)),
      authorLastName: new UntypedFormControl(null, Validators.maxLength(64)),
      authorEntity: new UntypedFormControl(null, Validators.maxLength(64)),
      status: new UntypedFormControl(null),
      irriLocation: new UntypedFormControl(null),
      actionDateStart: new UntypedFormControl(null),
      actionDateEnd: new UntypedFormControl(null),
    });

    this.statuses = [
      'STATUS.IN_PROGRESS',
      'STATUS.TO_VALIDATE',
      'STATUS.VALIDATED',
      'STATUS.CANCELED',
    ];

    this.irriLocations = ['FRA', 'EUR'];

    this.types = ['COMPLETE', 'NTD', 'TS', 'PRO'];

    this.userService.user.subscribe(
      (data) => {
        this.user = data;
      },
      (err) => console.error(err)
    );
  }

  ngOnInit(): void {
    this.adapter.setLocale(this.translate.currentLang);
    this.changeDetectorRef.detectChanges();
  }

  getContent(): void {
    this.criteriaApplied = true;
    let criteria = this.criteriaForm.getRawValue();
    criteria = this.prepareCriteria(criteria);
    this.criteriaExpanded = false;
    this.criteria.emit(criteria);
  }

  getSynthesisFile(): void {
    let criteria = this.criteriaForm.getRawValue();
    criteria = this.prepareCriteria(criteria);
    this.criteriaExpanded = false;
    this.synthesis.emit(criteria);
  }

  resetForm(): void {
    this.criteriaForm.reset();
    this.criteriaApplied = false;
    this.criteriaExpanded = false;
    this.criteria.emit(this.criteriaForm.getRawValue());
  }

  prepareCriteria(criteria): void {
    criteria.id = criteria.id ? criteria.id : null;
    criteria.type = criteria.type ? criteria.type : null;
    criteria.authorFirstname = criteria.authorFirstname
      ? criteria.authorFirstname
      : null;
    criteria.authorLastname = criteria.authorLastname
      ? criteria.authorLastname
      : null;
    criteria.authorEntity = criteria.authorEntity
      ? criteria.authorEntity
      : null;
    criteria.status = criteria.status ? criteria.status : null;
    criteria.actionDateStart = criteria.actionDateStart
      ? criteria.actionDateStart
      : null;
    criteria.actionDateEnd = criteria.actionDateEnd
      ? criteria.actionDateEnd
      : null;
    criteria.language = this.translate.currentLang;
    return criteria;
  }
}
