import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from './material-components/material-components.module';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { HttpTranslateService } from './../services/http-translate.service';
import { CustomTranslationLoader } from './../models/CustomTranslationLoader';
import { CustomCompiler } from '../models/CustomCompiler';
import { TableButtonComponent } from './table-button/table-button.component';
import { AuditComponent } from './audit/audit.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { SharedModule } from '../shared/shared.module';

export function createCustomTranslateLoader(httpTranslateService: HttpTranslateService) {
  return new CustomTranslationLoader(httpTranslateService);
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TableButtonComponent,
    AuditComponent,
    ConfirmComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialComponentsModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {provide: TranslateLoader, useFactory: (createCustomTranslateLoader), deps: [HttpTranslateService]},
      compiler: {provide: TranslateCompiler, useClass: CustomCompiler}
    })
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    TableButtonComponent,
    AuditComponent
  ]
})
export class ComponentsModule { }
