import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {forkJoin} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {of as observableOf} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class HttpTranslateService {

  constructor(public http: HttpClient) { }

  getTranslationByLang(lang: string): Observable<any> {
    return forkJoin([this.http.get<any[]>(`irri/api/v1/translate/load?lang=${lang}`).pipe(catchError(error => observableOf(error))),
      this.http.get<any[]>(`./assets/i18n/${lang}.json`)]);
  }

  getTranslationByParent(parent: string): Observable<any[]> {
    return this.http.get<any[]>(`irri/api/v1/translate/constants?parent=${parent}`);
  }

  getAllTranslationByParent(parent: string): Observable<any[]> {
    return this.http.get<any[]>(`irri/api/v1/translate/all-constants?parent=${parent}`);
  }

  getAllConstants(): Observable<any> {
    return this.http.get<any>(`irri/api/v1/translate`);
  }
}
