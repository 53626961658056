import { environment } from '../../environments/environment';

export class User {

    public country: string;
    public etabCode: String;
    public etabDesc: String;
    public email: string;
    public firstName: string;
    public iup: string;
    public lastName: string;
    public authorities: string[] = [];

    constructor(data: any) {
      if (data) {
        this.country = data.country;
        this.etabCode = data.etabCode;
        this.etabDesc = data.etabDesc;
        this.email = data.email;
        this.firstName = data.firstName;
        this.iup = data.iup;
        this.lastName = data.lastName;

        this.extractedAuthorities(data);
      }
    }

    private extractedAuthorities(data: any) {
      if (data.authorities) {
        Array.prototype.forEach.call(data.authorities, obj => {
          const authority = obj.authority;
          this.authorities.push(authority);
        });
      }
    }

    isAdmin(){
      return this.authorities.indexOf(environment.adminProfile) > -1;
    }

    isUser(){
      return this.authorities.indexOf(environment.userProfile) > -1;
    }

    isValidator(){
      return this.authorities.indexOf(environment.validatorProfile) > -1;
    }

  }
