import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'natixis-irri-front';

  constructor(private translate: TranslateService) {
  }
  
  OnChangeLanguage(event) {
    if (event) {
      console.log(event);
      this.translate.use(event);
    }
  }
}
