import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ProfessionalRequest } from '../../../models/ProfessionalRequest';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfessionalService } from '../../../services/professional.service';
import { filter } from 'rxjs/operators';
import { ConfirmComponent } from '../../../components/confirm/confirm.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { HttpTranslateService } from '../../../services/http-translate.service';

@Component({
  selector: 'app-professional',
  templateUrl: './professional.component.html',
  styleUrls: ['./professional.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class ProfessionalComponent implements OnInit {

  @Input() professional: ProfessionalRequest;
    professionalForm: UntypedFormGroup;
    pole: any[];
    currencies: any[];
    perimeters: any[];
    entities: any[];
    isLoadingResults = false;
    isEdit = false;
    isViewOnly = false;

  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private professionalService: ProfessionalService,
    private translate: TranslateService,
    private httpTranslateService: HttpTranslateService,
    public route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public adapter: DateAdapter<any>
  ) {

    this.translate.onLangChange.subscribe(data => this.adapter.setLocale(data.lang));

    router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(event => {
        this.isViewOnly = (event.url.indexOf('/view') >= 0);
        this.isEdit = (event.url.indexOf('/edit') >= 0);

        if (this.isEdit || this.isViewOnly){
          this.route.data.subscribe((data: any) => {
            this.professional = data.irr;

            this.professionalForm.patchValue({
              id: this.professional.id,
              period: this.professional.period,
              startDateMembership: this.professional.startDateMembership,
              expiryDatemembership: this.professional.expiryDatemembership,
              pole: this.professional.pole,
              entityInvolved: this.professional.entityInvolved,
              associationName: this.professional.associationName,
              assessmentDate: this.professional.assessmentDate,
              assessmentAmount: this.professional.assessmentAmount,
              currency: this.professional.currency,
              equivalent: this.professional.equivalent,
              newMembership: this.professional.newMembership,
              perimeter: this.professional.perimeter,
              actionRepresentationInterest: this.professional.actionRepresentationInterest,
              decisionComment: this.professional.decisionComment
            });
          });
        }

        this.fillDropdowns();
    });

    this.professionalForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      period: new UntypedFormControl(''),
      startDateMembership: new UntypedFormControl(''),
      expiryDatemembership: new UntypedFormControl(''),
      pole: new UntypedFormControl(''),
      entityInvolved: new UntypedFormControl(''),
      associationName: new UntypedFormControl(''),
      assessmentDate: new UntypedFormControl(''),
      assessmentAmount: new UntypedFormControl(''),
      currency: new UntypedFormControl(''),
      equivalent: new UntypedFormControl(''),
      newMembership: new UntypedFormControl(''),
      perimeter: new UntypedFormControl(''),
      actionRepresentationInterest: new UntypedFormControl(''),
      decisionComment: new UntypedFormControl('')
    });

    this.translate.onLangChange.subscribe(() => {
      this.fillDropdowns();
    });

  }

  ngOnInit(): void {
    this.adapter.setLocale(this.translate.currentLang);
    this.changeDetectorRef.detectChanges();
  }

  fillDropdowns(): void{

    if (!this.isViewOnly){
      this.httpTranslateService.getTranslationByParent('ACTION_POLES').subscribe((data: any) => {
        this.pole = data;
      });

      this.httpTranslateService.getTranslationByParent('ENTITIES').subscribe((data: any) => {
        this.entities = data;
      });
    }else{
      this.httpTranslateService.getAllTranslationByParent('ACTION_POLES').subscribe((data: any) => {
        this.pole = data;
      });

      this.httpTranslateService.getAllTranslationByParent('ENTITIES').subscribe((data: any) => {
        this.entities = data;
      });
    }

    this.translate.get('PERIMETER').subscribe((data: any) => {
      this.perimeters = data;
    });
    this.translate.get('CURRENCIES').subscribe((data: any) => {
      this.currencies = data;
    });
  }

  confirmActionGDPR(action: string): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '40%',
      disableClose: true,
      data: {
        action
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'save'){
          this.save();
        }else if (result.action === 'update'){
          this.update();
        }else if (result.action === 'requestValidate'){
          this.requestValidate();
        }else if (result.action === 'rejectProfessional'){
          this.rejectProfessional();
        }else if (result.action === 'validateProfessional'){
          this.validateProfessional();
        }
      }
    });
  }

  save(): void {
    if ( this.professionalForm.valid ) {
      this.isLoadingResults = true;
      const professionalRequest = this.professionalForm.getRawValue();

      console.log(professionalRequest);

      professionalRequest.declarationType = 'PRO';

      this.professionalService.save(professionalRequest).subscribe(
        (data) => {
          this.isLoadingResults = false;
          this.router.navigate(['/dashboard']);
        }
      );

    } else {
      this.validateFormFields(this.professionalForm);
      this.professionalForm.updateValueAndValidity();
    }

  }

  update(): void {
    this.validateFormFields(this.professionalForm);
    this.professionalForm.updateValueAndValidity();

    if ( this.professionalForm.valid ) {
      this.isLoadingResults = true;
      const professionalRequest = this.professionalForm.getRawValue();

      professionalRequest.declarationType = 'PRO';

      this.professionalService.update(professionalRequest).subscribe(
        (data) => {
          this.isLoadingResults = false;
          this.router.navigate(['/dashboard']);
        }
      );

    } else {
      this.professionalForm.updateValueAndValidity();
    }

  }

  requestValidate(): void {
    if ( this.professionalForm.valid ) {
      this.isLoadingResults = true;

      const professionalRequest = this.professionalForm.getRawValue();

      professionalRequest.declarationType = 'PRO';

      this.professionalService.sendToValidation(professionalRequest).subscribe(
        (data) => {
          this.isLoadingResults = false;
          this.router.navigate(['/dashboard']);
        },
        (error) => {
          this.isLoadingResults = false;
          console.log(error);
        }
      );
    }
  }

  rejectProfessional(): void {
    this.isLoadingResults = true;
    this.professionalService.reject(this.professional.id).subscribe(
      (data) => {
        this.isLoadingResults = false;
        this.router.navigate(['/dashboard']);
      },
      (error) => {
        this.isLoadingResults = false;
        console.log(error);
      }
    );
  }

  validateProfessional(): void {
    this.isLoadingResults = true;

    const professionalRequest = this.professionalForm.getRawValue();

    professionalRequest.declarationType = 'PRO';

    this.professionalService.validate(professionalRequest).subscribe(
      (data) => {
        this.isLoadingResults = false;
        this.router.navigate(['/dashboard']);
      },
      (error) => {
        this.isLoadingResults = false;
        console.log(error);
      }
    );
  }

  validateFormFields(fields) {
    try {
      Object.entries(fields.controls).forEach((control: any) => {
        console.log(control);
        if (control[0] === 'publicResponsibles' || control[0] === 'peopleInvolved'
        ) {
          this.validateFormFields(control[1]);
        } else {
          if (control[1].controls) {
            Object.entries(control[1].controls).forEach((c: any) => {
              c[1].touched = true;
            });
          } else {
            control[1].touched = true;
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

}
