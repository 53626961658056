import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component'
import { MaterialComponentsModule } from '../components/material-components/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './error/error.component';
import { ComponentsModule } from '../components/components.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { FormModule } from './form/form.module'
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { ContactComponent } from './contact/contact.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    NotFoundComponent,
    ErrorComponent,
    LegalNoticeComponent,
    ContactComponent
  ],
  imports: [
    CommonModule,
    DashboardModule,
    FormModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule,
    SharedModule
  ],
  exports: [
    NotFoundComponent,
    ErrorComponent,
    LegalNoticeComponent,
    ContactComponent
  ]
})
export class PagesModule { }
