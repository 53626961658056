export class Util {

    sort(array: any, key: string) : any[] {
        if (!array) {
          return null;
        }
    
        return array.sort((itemA: any, itemB: any) => {
          if (itemA[key] > itemB[key]) {
            return 1;
          } else if (itemA[key] < itemB[key]) {
            return -1;
          } else {
            return 0;
          }
        });
      }
}