import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../../components/material-components/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IRRComponent} from './irr/irr.component';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { createCustomTranslateLoader } from 'src/app/components/components.module';
import { HttpTranslateService } from 'src/app/services/http-translate.service';
import { CustomCompiler } from 'src/app/models/CustomCompiler';
import { TimeSpentComponent } from './time-spent/time-spent.component';
import { ProfessionalComponent } from './professional/professional.component';

@NgModule({
  declarations: [
    IRRComponent,
    TimeSpentComponent,
    ProfessionalComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {provide: TranslateLoader, useFactory: (createCustomTranslateLoader), deps: [HttpTranslateService]},
      compiler: {provide: TranslateCompiler, useClass: CustomCompiler}
    })
  ],
  exports: [
  ]
})
export class FormModule { }
