import {
  Component,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { User } from '../../models/User';
import { ProfilePipe } from '../../shared/pipes/profile.pipe';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Output() changeLanguage = new EventEmitter<string>();
  formIsActive: boolean;
  timeIsActive: boolean;
  professionalIsActive: boolean;
  dashboardIsActive: boolean;
  profiles: Array<any>;
  profilesString: string;
  user: User;
  profilePipe = new ProfilePipe();

  constructor(
    private router: Router,
    public userService: UserService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.dashboardIsActive =
          event.url === '/dashboard' || event.url === '/';
        this.formIsActive = event.url === '/irr';
        this.timeIsActive = event.url === '/time-spent';
        this.professionalIsActive = event.url === '/professional';
      });
    this.translate.currentLang = '';
  }

  ngOnInit(): void {
    this.userService.user.subscribe(
      (data) => {
        this.user = data;
        if (this.user && data) {
          this.profiles = data.authorities;
          this.returnProfiles();
        }
      },
      (err) => console.error(err)
    );
  }

  onChangeLanguage(value): void {
    this.changeLanguage.emit(value);
  }

  returnProfiles() {
    let profilesString = '';
    this.profiles.forEach((element) => {
      profilesString =
        profilesString + '\n' + this.profilePipe.transform(element);
    });
    this.profilesString = profilesString;
  }
}
