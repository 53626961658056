import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PaginationSort } from 'src/app/models/pagination-sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IrriService } from 'src/app/services/irri.service';
import { AuditComponent } from 'src/app/components/audit/audit.component';
import { Utils } from '../../../models/Utils';
import { ConfirmComponent } from 'src/app/components/confirm/confirm.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  criteria: any;
  pagination: PaginationSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  resultsLength = 0;
  pageSize: number;
  isLoadingResults: boolean;

  constructor(
    private irriService: IrriService,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.pageSize = +localStorage.getItem('pageSize');
  }

  changeCriteria(criteria: any): void {
    this.criteria = criteria;
    this.getByCriteria();
  }

  changePagination(pagination: PaginationSort): void {
    this.pagination = pagination;
    localStorage.setItem('pageSize', this.pagination.pageSize.toString());
    this.pageSize = +localStorage.getItem('pageSize');
    this.criteria = this.criteria || {};
    this.getByCriteria();
  }


  getByCriteria(): void {
    this.isLoadingResults = true;
    this.changeDetectorRef.detectChanges();
    this.irriService.getByCriteria(this.criteria, this.pagination).subscribe(
      (data: any) => {
        this.dataSource.data = data.content;
        this.resultsLength = data.totalElements;
        this.isLoadingResults = false;
      },
      err => {
        console.error(err);
        this.isLoadingResults = false;
      }
    );
  }

  openIRR(event): void {
    console.log(event);
    if (event.type === 'COMPLETE' || event.type === 'NTD'){
      this.router.navigate([`/irr/view/${event.id}`]);
    }else if (event.type === 'TS'){
      this.router.navigate([`/time-spent/view/${event.id}`]);
    }else if (event.type === 'PRO'){
      this.router.navigate([`/professional/view/${event.id}`]);
    }
  }

  editIRR(event): void {
    if (event.type === 'COMPLETE' || event.type === 'NTD'){
      this.router.navigate([`/irr/edit/${event.id}`]);
    }else if (event.type === 'TS'){
      this.router.navigate([`/time-spent/edit/${event.id}`]);
    }else if (event.type === 'PRO'){
      this.router.navigate([`/professional/edit/${event.id}`]);
    }
  }

  cancelIRR(event): void {
    this.confirmActionGDPR('cancelIRR', event);
  }

  rejectIrr(event): void {
    this.confirmActionGDPR('rejectIrr', event);
  }

  validateIrr(event): void {
    if (event.type === 'COMPLETE' || event.type === 'NTD'){
      this.router.navigate([`/irr/edit/${event.id}`]);
    }else if (event.type === 'TS'){
      this.router.navigate([`/time-spent/edit/${event.id}`]);
    }else if (event.type === 'PRO'){
      this.router.navigate([`/professional/edit/${event.id}`]);
    }
  }

  confirmActionGDPR(action: string, param: string): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '40%',
      disableClose: true,
      data: {
        action,
        param
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'rejectIrr'){
          this.irriService.reject(result.param).subscribe(
            (data) => {
              this.getByCriteria();
            },
            (error) => {
              console.log(error);
            }
          );
        }else if (result.action === 'cancelIRR'){
          this.irriService.cancel(result.param).subscribe(
            (data) => {
              this.getByCriteria();
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    });
  }

  showAudits(event): void {
    this.dialog.open(AuditComponent, {
      width: '80%',
      height: '85%',
      disableClose: true,
      data: {id: event}
    });
  }

  getSynthesisFile(criteria: any): void {
    this.criteria = criteria;
    this.irriService.getSynthesis(this.criteria).subscribe(
        data => {
            const d = new Date();
            const dformat = [(d.getMonth() + 1),
                    d.getDate(),
                    d.getFullYear()].join('-') + ' ' +
                [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');
            Utils.download(data.body, `IRRI_Synthesis_${dformat}.xlsx`);
            this.isLoadingResults = false;
        },
        err => {
            console.error(err);
            this.isLoadingResults = false;
        }
    );
  }

}
