import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IrriService } from '../../../services/irri.service';
import { IRRRequest } from 'src/app/models/IRRRequest';
import { TranslateService  } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, startWith, debounceTime, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import { RpgService } from '../../../services/rpg.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from 'src/app/components/confirm/confirm.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { User } from '../../../models/User';
import { UserService } from '../../../services/user.service';
import { Util } from '../../../shared/util/util';
import { HttpTranslateService } from '../../../services/http-translate.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-irr',
  templateUrl: './irr.component.html',
  styleUrls: ['./irr.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class IRRComponent implements OnInit, AfterViewInit {

    @Input() irr: IRRRequest;
    irrForm: UntypedFormGroup;
    statuses: Array<any>;
    isLoadingResults = false;
    isEdit = false;
    isViewOnly = false;
    platforms: any[];
    entities: any[];
    categories: any[];
    decisions: any[];
    modalities: any[];
    initiatives: any[];
    currencies: any[];
    irriLocations: any[];
    declarationTypes: any[];
    interests: any[];

    filteredFirstNameRpg: Observable<any>;
    filteredLastNameRpg: Observable<any>;
    filteredAuthorFirstNameRpg: Observable<any>;
    filteredAuthorLastNameRpg: Observable<any>;

    peopleInvolved: UntypedFormArray;
    publicResponsibles: UntypedFormArray;

    authorEmailHasError: boolean;
    authorIUPHasError: boolean;
    otherEmailHasError: boolean;
    otherIUPHasError: boolean;

    user: User;
    periodDate: Date;
    minDate: Date;
    maxDate: Date;

    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    constructor(
      public router: Router,
      private formBuilder: UntypedFormBuilder,
      private irriService: IrriService,
      private translate: TranslateService,
      private rpgService: RpgService,
      private httpTranslateService: HttpTranslateService,
      public route: ActivatedRoute,
      private readonly changeDetectorRef: ChangeDetectorRef,
      public dialog: MatDialog,
      public adapter: DateAdapter<any>,
      public userService: UserService,
      public util: Util
    ) {


      this.periodDate = new Date();
      this.periodDate.setDate(1);
      this.periodDate.setMonth(this.periodDate.getMonth() - 1);

      this.minDate = new Date();
      this.minDate.setDate(1);
      this.minDate.setMonth(this.minDate.getMonth() - 1);

      const newDate = new Date();
      this.maxDate = new Date(newDate.getFullYear(), newDate.getMonth(), 0);

      this.userService.user.subscribe(
        (data) => {
            this.user = data;
        },
        (err) => console.error(err)
      );

      this.authorEmailHasError = false;
      this.authorIUPHasError = false;

      this.otherEmailHasError = false;
      this.otherIUPHasError = false;

      this.translate.onLangChange.subscribe(data => this.adapter.setLocale(data.lang));

      router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe(event => {
          this.isViewOnly = (event.url.indexOf('/view') >= 0);
          this.isEdit = (event.url.indexOf('/edit') >= 0);

          if (this.isEdit || this.isViewOnly){
            this.route.data.subscribe((data: any) => {
              this.irr = data.irr;

              this.irrForm.patchValue({
                authorEntity: this.irr.authorEntity,
                authorFirstName: this.irr.authorFirstName,
                authorLastName: this.irr.authorLastName,
                authorIup: this.irr.authorIup,
                authorEmail: this.irr.authorEmail,
              });

              if ( typeof(this.irr.peopleInvolved) === 'string' ) {
                this.irr.peopleInvolved = JSON.parse(data.irr.peopleInvolved);

                this.irr.peopleInvolved.forEach( (element, index) => {
                  this.addPersonInvolved(
                    index,
                    element.firstName,
                    element.lastName,
                    element.iup,
                    element.email
                  );
                });
              }

              if ( typeof(this.irr.publicResponsibles) === 'string' ) {
                this.irr.publicResponsibles = JSON.parse(data.irr.publicResponsibles);

                this.irr.publicResponsibles.forEach( (element, index) => {
                  this.addPublicResponsible(
                    element.responsibleFirstName,
                    element.responsibleLastName,
                    element.responsibleEntity,
                    element.responsibleCategory,
                    element.responsibleFunction
                  );
                });
              }

              this.irrForm.patchValue({
                id: this.irr.id,
                actionDate: this.irr.actionDate,
                actionRepresentationInterest: this.irr.actionRepresentationInterest,
                declarationType: this.irr.declarationType,
                irriLocation: this.irr.irriLocation,
                platform: this.irr.platform,
                forThird: this.irr.forThird,
                entityInvolved: this.irr.entityInvolved,
                representedByAssociation: this.irr.representedByAssociation,
                associationName: this.irr.associationName,
                interest: this.irr.interest,
                mainDossier: this.irr.mainDossier,
                implPolicies: this.irr.implPolicies,
                participationEU: this.irr.participationEU,
                decision: this.irr.decision,
                decisionDetail: this.irr.decisionDetail,
                position: this.irr.position,
                modality: this.irr.modality,
                initiative: this.irr.initiative,
                amountSpent: this.irr.amountSpent,
                currency: this.irr.currency,
                equivalent: this.irr.equivalent,
                comment: this.irr.comment,
                decisionComment: this.irr.decisionComment,
                period: this.irr.period
              });
            });
          }

          this.fillDropdowns();
      });

      this.irrForm = new UntypedFormGroup({
        id: new UntypedFormControl(''),
        actionDate: new UntypedFormControl(null),
        actionRepresentationInterest: new UntypedFormControl(''),
        declarationType: new UntypedFormControl(''),
        irriLocation: new UntypedFormControl(''),
        platform: new UntypedFormControl(''),
        authorEntity: new UntypedFormControl(''),
        authorFirstName: new UntypedFormControl('', [Validators.minLength(3), this.isAuthorNatixisEmployee()]),
        authorLastName: new UntypedFormControl('', [Validators.minLength(3), this.isAuthorNatixisEmployee()]),
        authorIup: new UntypedFormControl(''),
        authorEmail: new UntypedFormControl(''),
        forThird: new UntypedFormControl(''),
        entityInvolved: new UntypedFormControl(''),
        representedByAssociation: new UntypedFormControl(''),
        associationName: new UntypedFormControl(''),
        interest: new UntypedFormControl(''),
        mainDossier: new UntypedFormControl(''),
        implPolicies: new UntypedFormControl(''),
        participationEU: new UntypedFormControl(''),
        peopleInvolved: this.formBuilder.array([]),
        publicResponsibles: this.formBuilder.array([]),
        recipientFirstName: new UntypedFormControl(''),
        recipientEntity: new UntypedFormControl(''),
        recipientFunction: new UntypedFormControl(''),
        recipientLastName: new UntypedFormControl(''),
        category: new UntypedFormControl(''),
        decision: new UntypedFormControl(''),
        decisionDetail: new UntypedFormControl(''),
        position: new UntypedFormControl(''),
        modality: new UntypedFormControl(''),
        initiative: new UntypedFormControl(''),
        amountSpent: new UntypedFormControl(''),
        currency: new UntypedFormControl(''),
        equivalent: new UntypedFormControl(''),
        comment: new UntypedFormControl(''),
        decisionComment: new UntypedFormControl(''),
        period: new UntypedFormControl((this.periodDate.getMonth() + 1) + '/' + (this.periodDate.getFullYear()))
      });

      this.translate.onLangChange.subscribe(() => {
        this.fillDropdowns();
      });

      this.filteredAuthorFirstNameRpg = this.irrForm.get('authorFirstName').valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        switchMap(value => {
          if (value && value.length >= 3){
            this.irrForm.patchValue({
              authorIup: '',
              authorEmail: '',
            });
            return (this.rpgService.getUsersByLastNameAndFirstName('', value));
          }
          return observableOf([]);
        }),
        map(data => {
            return data;
        }),
        catchError(() => {
            return observableOf([]);
        })
      );

      this.filteredAuthorLastNameRpg = this.irrForm.get('authorLastName').valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        switchMap(value => {
          if (value && value.length >= 3){
            this.irrForm.patchValue({
              authorIup: '',
              authorEmail: '',
            });
            return (this.rpgService.getUsersByLastNameAndFirstName(value, ''));
          }
          return observableOf([]);
        }),
        map(data => {
            return data;
        }),
        catchError(() => {
            return observableOf([]);
        })
      );
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
      this.adapter.setLocale(this.translate.currentLang);
      this.changeDetectorRef.detectChanges();
    }

    fillDropdowns(): void{
      if (!this.isViewOnly){
        this.httpTranslateService.getTranslationByParent('ACTION_POLES').subscribe((data: any) => {
          this.platforms = data;
        });

        this.httpTranslateService.getTranslationByParent('ENTITIES').subscribe((data: any) => {
          this.entities = data;
        });
      }else{
        this.httpTranslateService.getAllTranslationByParent('ACTION_POLES').subscribe((data: any) => {
          this.platforms = data;
        });

        this.httpTranslateService.getAllTranslationByParent('ENTITIES').subscribe((data: any) => {
          this.entities = data;
        });
      }

      this.translate.get('CATEGORIES').subscribe((data: any) => {
        this.categories = this.util.sort(data, "label");
      });

      this.translate.get('DECISIONS').subscribe((data: any) => {
        this.decisions = data;
      });

      this.translate.get('MODALITIES').subscribe((data: any) => {
        this.modalities = data;
      });

      this.translate.get('INITIATIVES').subscribe((data: any) => {
        this.initiatives = data;
      });

      this.translate.get('CURRENCIES').subscribe((data: any) => {
        this.currencies = data;
      });

      this.translate.get('IRRI_LOCATIONS').subscribe((data: any) => {
        this.irriLocations = data;
      });

      this.translate.get('DECLARATION_TYPES').subscribe((data: any) => {
        this.declarationTypes = data;
      });

      this.translate.get('INTERESTS').subscribe((data: any) => {
        this.interests = data;
      });

    }

    save(submitAfterSave?: boolean): void {
      console.log(this.irrForm);
      if ( this.irrForm.valid || this.irrForm.get('declarationType').value === 'NTD' ) {
        this.isLoadingResults = true;
        const irrRequest = this.irrForm.getRawValue();

        irrRequest.peopleInvolved = JSON.stringify(irrRequest.peopleInvolved);
        irrRequest.publicResponsibles = JSON.stringify(irrRequest.publicResponsibles);

        irrRequest.equivalent = irrRequest.currency == 'EUR' ? '' : irrRequest.equivalent;

        const acd = new Date(irrRequest.actionDate);
        acd.setTime( acd.getTime() - acd.getTimezoneOffset() * 60 * 1000 );

        irrRequest.actionDate = acd.toISOString();

        this.irriService.save(irrRequest).subscribe(
          (data) => {
            this.isLoadingResults = false;
            if (submitAfterSave) {
              if (data != null) {
                this.irrForm.patchValue({
                  id: data.id
                });
                this.requestValidate();
              }
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
        );

      } else {
        this.validateFormFields(this.irrForm);
        this.irrForm.updateValueAndValidity();
      }

    }

    update(): void {
      this.validateFormFields(this.irrForm);
      this.irrForm.updateValueAndValidity();

      if ( this.irrForm.valid ) {
        this.isLoadingResults = true;
        const irrRequest = this.irrForm.getRawValue();

        irrRequest.peopleInvolved = JSON.stringify(irrRequest.peopleInvolved);
        irrRequest.publicResponsibles = JSON.stringify(irrRequest.publicResponsibles);

        irrRequest.equivalent = irrRequest.currency == 'EUR' ? '' : irrRequest.equivalent;

        const acd = new Date(irrRequest.actionDate);
        acd.setTime( acd.getTime() - acd.getTimezoneOffset() * 60 * 1000 );

        irrRequest.actionDate = acd.toISOString();

        this.irriService.update(irrRequest).subscribe(
          (data) => {
            this.isLoadingResults = false;
            this.router.navigate(['/dashboard']);
          }
        );

      } else {
        this.irrForm.updateValueAndValidity();
      }

    }

    resetForm(): void{
      this.irrForm.reset();
    }

    createPublicResponsibleInput(firstName, lastName, entity, category, func): UntypedFormGroup {
      return this.formBuilder.group({
        responsibleFirstName: [firstName, [Validators.required]],
        responsibleLastName: [lastName, [Validators.required]],
        responsibleEntity: [entity, Validators.required],
        responsibleCategory: [category, Validators.required],
        responsibleFunction: [func, Validators.required]
      });
    }

    addPublicResponsible(firstName, lastName, entity, category, func): void {
      this.publicResponsibles = this.irrForm.get('publicResponsibles') as UntypedFormArray;
      this.publicResponsibles.push(this.createPublicResponsibleInput(firstName, lastName, entity, category, func));
    }

    createOtherInvolvedInput(index, firstName, lastName, iup, email): UntypedFormGroup {
      return this.formBuilder.group({
        firstName: [firstName, [Validators.required, Validators.minLength(3), this.isNatixisEmployee(index)]],
        lastName: [lastName, [Validators.required, Validators.minLength(3), this.isNatixisEmployee(index)]],
        iup: [iup, Validators.required],
        email: [email, Validators.required]
      });
    }

    addPersonInvolved(index, firstName, lastName, iup, email): void {
      this.peopleInvolved = this.irrForm.get('peopleInvolved') as UntypedFormArray;
      this.peopleInvolved.push(this.createOtherInvolvedInput(index, firstName, lastName, iup, email));

      for (const control of this.peopleInvolved.controls) {
        // listen to changes in each barcode, if change, do something!
        this.filteredFirstNameRpg = control.get('firstName').valueChanges.pipe(
          startWith(''),
          debounceTime(100),
          switchMap((value) => {
            if (value && value.length >= 3){
              control.patchValue({
                iup: '',
                email: ''
              });
              return this.rpgService.getUsersByLastNameAndFirstName('', value);
            }
            return observableOf([]);
          }),
          map(data => {
              return data;
          }),
          catchError(() => {
              return observableOf([]);
          })
        );

        this.filteredLastNameRpg = control.get('lastName').valueChanges.pipe(
          startWith(''),
          debounceTime(100),
          switchMap((value) => {
            if (value && value.length >= 3) {
              control.patchValue({
                iup: '',
                email: ''
              });
              return this.rpgService.getUsersByLastNameAndFirstName(value, '');
            }
            return observableOf([]);
          }),
          map(data => {
              return data;
          }),
          catchError(() => {
              return observableOf([]);
          })
        );
      }

      this.changeDetectorRef.detectChanges();

    }

    removePersonInvolved(index: number): void {
      this.peopleInvolved.removeAt(index);
    }

    removePublicResponsible(index: number): void {
      this.publicResponsibles.removeAt(index);
    }

    personInvolvedPatchValues(data, index): void{
      !data.email ? this.otherEmailHasError = true : this.otherEmailHasError = false;
      !data.iup ? this.otherIUPHasError = true : this.otherIUPHasError = false;
      const control = this.peopleInvolved.controls[index];
      control.patchValue({
        iup: data.iup,
        email: data.email,
        firstName: data.firstname,
        lastName: data.lastname
      }, {onlySelf: true, emitEvent: false});
      control.updateValueAndValidity();
      this.validateFormFields(this.irrForm);
    }

    authorPatchValues(data): void{
      !data.email ? this.authorEmailHasError = true : this.authorEmailHasError = false;
      !data.iup ? this.authorIUPHasError = true : this.authorIUPHasError = false;
      this.irrForm.patchValue({
        authorIup: data.iup,
        authorEmail: data.email,
        authorFirstName: data.firstname,
        authorLastName: data.lastname
      }, {onlySelf: true, emitEvent: false});
    }

    requestValidate(): void {
      if ( this.irrForm.valid ) {
        this.isLoadingResults = true;

        const irrRequest = this.irrForm.getRawValue();
        irrRequest.peopleInvolved = JSON.stringify(irrRequest.peopleInvolved);
        irrRequest.publicResponsibles = JSON.stringify(irrRequest.publicResponsibles);

        const acd = new Date(irrRequest.actionDate);
        acd.setTime( acd.getTime() - acd.getTimezoneOffset() * 60 * 1000 );

        irrRequest.actionDate = acd.toISOString();

        this.irriService.sendToValidation(irrRequest).subscribe(
          (data) => {
            this.isLoadingResults = false;
            this.router.navigate(['/dashboard']);
          },
          (error) => {
            this.isLoadingResults = false;
            console.log(error);
          }
        );
      }else{
        this.validateFormFields(this.irrForm);
      }
    }

    validateIRR(): void {
      if ( this.irrForm.valid ) {
        this.isLoadingResults = true;

        const irrRequest = this.irrForm.getRawValue();
        irrRequest.peopleInvolved = JSON.stringify(irrRequest.peopleInvolved);
        irrRequest.publicResponsibles = JSON.stringify(irrRequest.publicResponsibles);

        const acd = new Date(irrRequest.actionDate);
        acd.setTime( acd.getTime() - acd.getTimezoneOffset() * 60 * 1000 );

        irrRequest.actionDate = acd.toISOString();

        this.irriService.validate(irrRequest).subscribe(
          (data) => {
            this.isLoadingResults = false;
            this.router.navigate(['/dashboard']);
          },
          (error) => {
            this.isLoadingResults = false;
            console.log(error);
          }
        );
      }else{
        this.validateFormFields(this.irrForm);
      }
    }

    rejectIRR(): void {
      this.isLoadingResults = true;
      this.irriService.reject(this.irr.id).subscribe(
        (data) => {
          this.isLoadingResults = false;
          this.router.navigate(['/dashboard']);
        },
        (error) => {
          this.isLoadingResults = false;
          console.log(error);
        }
      );
    }

    confirmActionGDPR(action: string): void {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '40%',
        disableClose: true,
        data: {
          action
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.action === 'save'){
            this.save();
          }else if (result.action === 'update'){
            this.update();
          }else if (result.action === 'requestValidate'){
            this.requestValidate();
          }else if (result.action === 'rejectIRR'){
            this.rejectIRR();
          }else if (result.action === 'validateIRR'){
            this.validateIRR();
          }else if (result.action === 'redirectToEdit'){
            this.router.navigate([`/irr/edit/${this.irr.id}`]);
          }else if (result.action === 'saveAndSubmit'){
            this.save(true);
          }
        }
      });
    }

    isNatixisEmployee(index): ValidatorFn{
      return (control: AbstractControl): ValidationErrors | null => {
        const iupControl = this.peopleInvolved.controls[index];
        if (iupControl){
          return !iupControl.get('iup').value ? {notNatixisEmployee: {value: control.value}} : null;
        }
        return {notNatixisEmployee: {value: control.value}};
      };
    }

    isAuthorNatixisEmployee(): ValidatorFn{
      return (control: AbstractControl): ValidationErrors | null => {
        if (this.irrForm){
          const iupControl = this.irrForm.get('authorIup');
          if (iupControl){
            return !iupControl.value ? {notNatixisEmployee: {value: control.value}} : null;
          }
        }
        return {notNatixisEmployee: {value: control.value}};
      };
    }

    validateFormFields(fields) {
      try {
        Object.entries(fields.controls).forEach((control: any) => {
          console.log(control);
          if (control[0] === 'publicResponsibles' || control[0] === 'peopleInvolved'
          ) {
            this.validateFormFields(control[1]);
          } else {
            if (control[1].controls) {
              Object.entries(control[1].controls).forEach((c: any) => {
                c[1].touched = true;
              });
            } else {
              control[1].touched = true;
            }

          }
        });
      } catch (e) {
        console.log(e);
      }
    }

  }
