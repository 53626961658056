import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { IRRRequest } from '../models/IRRRequest';
import { IrriService } from '../services/irri.service';
import { Injectable } from '@angular/core';

@Injectable()
export class IRRResolverService implements Resolve<IRRRequest> {

  constructor(public irriService: IrriService, public router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRRRequest> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.irriService.get(id).pipe(
      take(1),
      mergeMap(data => {
        if (data) {
          return of(data);
        } else { // id not found
          this.router.navigate(['/dashboard']);
          return EMPTY;
        }
      })
    );
  }
}
